import React, { useEffect, useState } from "react";
import { TextField, PrimaryButton } from "@fluentui/react";
import { Grid } from "@mui/material";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import CommonDataTable from "./CommonDataTable";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import JobConfigurationDialog from "./JobConfigurationDialog";

const JobConfigurations = (props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedJobId, setSelectedJobId] = useState("");
  const [jobInstance, setJobInstance] = useState([]);
  const [actulaLobData, setActulaLobData] = useState([]);
  const [jobInstanceFiltered, setJobInstanceFiltered] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);

  const column = [
    {
      name: "Id",
      selector: (row) => row.Id,
      sortable: true,
    },
    {
      name: "Job Id",
      selector: (row) => row?.Jobid,
    },
    {
      name: "CreatedOn",
      selector: (row) => {
        if (row.CreatedOn != null && row.CreatedOn != undefined) {
          const createdDate = new Date(row.CreatedOn).toISOString();
          return createdDate;
        } else {
          return "";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <PrimaryButton
          className="ref_button"
          text={
            <span
              style={{ color: "white", fontSize: "10px", fontWeight: "bold" }}
            >
              View
            </span>
          }
          styles={{
            rootHovered: {
              backgroundColor: "#251ca8",
            },
          }}
          onClick={() => {
            handleDialogOpen(row);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [props]);

  const handleDialogOpen = (row) => {
    setOpen(true);
    setSelectedJobId(row?.Jobid);
  };

  const handleDialogClose = (event, reason) => {
    if (reason == "backdropClick") {
      return;
    }
    setOpen(false);
  };

  const getData = async () => {
    let token = sessionStorage.getItem("token");
    document.body.classList.add("loading-indicator");
    token = await processAndUpdateToken(token);
    try {
      await getLobData(token);
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        baseUrl + "/api/Defaultdatum/GetallJobinstance",
        {
          headers,
        }
      );
      document.body.classList.remove("loading-indicator");
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        setJobInstance(response.data);
        setJobInstanceFiltered([]);
      } else {
        return "failed";
      }
    } catch (error) {
      document.body.classList.remove("loading-indicator");
      return "failed";
    }
  };

  const getLobData = async (token) => {
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(
        baseUrl + "/api/JobConfiguration/GetActualLobs",
        {
          headers,
        }
      );
      if (response.status !== 200) {
        return "failed";
      }
      if (response?.data) {
        setActulaLobData(response.data);
      } else {
        return "failed";
      }
    } catch (error) {
      setActulaLobData([]);
    }
  };

  const filterData = () => {
    if (searchText && searchText?.length > 0) {
      const filteredData = jobInstance?.filter((f) =>
        f?.Jobid?.toLowerCase()?.trim()?.includes(searchText?.trim())
      );
      setJobInstanceFiltered(filteredData);
      setIsSearch(true);
    } else {
      setJobInstanceFiltered([]);
      setIsSearch(false);
    }
  };

  const onChange = (text) => {
    if (text && text?.length > 0) {
      const filteredData = jobInstance?.filter((f) =>
        f?.Jobid?.toLowerCase()?.trim()?.includes(text?.trim())
      );
      setJobInstanceFiltered(filteredData);
    } else {
      setJobInstanceFiltered([]);
    }
  };

  return (
    <div style={{ width: "100%", paddingTop: "5px", marginLeft: "5px" }}>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <TextField
          placeholder="Search JobId..."
          value={searchText}
          onChange={(e, newValue) => {
            setSearchText(newValue);
          }}
          style={{
            width: "250px",
            marginRight: "8px",
          }}
        />
        <PrimaryButton
          text={
            <span style={{ color: "white", fontWeight: "bold" }}>Search</span>
          }
          onClick={() => {
            filterData();
          }}
          style={{
            marginLeft: "-50px",
            height: "32.1px",
            fontSize: "10px",
          }}
        />
      </div>
      <br />
      <div>
        <CommonDataTable
          key={"jData"}
          column={column}
          data={isSearch ? jobInstanceFiltered : jobInstance}
        />
      </div>
      <div>
        <Dialog
          key={"jacD"}
          open={open}
          onClose={handleDialogClose}
          fullScreen={true}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>Job - {selectedJobId} Configurations</span>
              <span style={{ fontSize: "smaller", color: "indianred" }}>
                Click 'esc' key to close
              </span>
            </div>
          </DialogTitle>
          <DialogContent dividers={true} component={"div"}>
            <DialogContentText
              id="scroll-dialog-description"
              tabIndex={-1}
              component={"div"}
            >
              <JobConfigurationDialog
                data={{ JobId: selectedJobId, actulaLobData }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button key="bCl" onClick={handleDialogClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default JobConfigurations;
