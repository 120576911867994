import React from "react";
import {PrimaryButton } from "@fluentui/react";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import axios from "axios";
import { baseUrl } from "../Services/Constants";

const Availablejobs = () => {
    const exportData = async () => {
        document.body.classList.add('loading-indicator');
        const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
        const headers = {
            Authorization: `Bearer ${Token}`,
            "Content-Type": "application/json",
        };
        const apiUrl = `${baseUrl}/api/Excel/AvailablejobExportExcel/Availablejobs-Excel`;
        try {
            const response = await axios({
                method: "Get",
                url: apiUrl,
                headers: headers,
                responseType: "blob",
            });
            if (response.status !== 200) {
                return "error";
            }

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `AvailableGridjobsReport_${new Date().toLocaleDateString()}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            sessionStorage.setItem("onUpdateClickCalled", false);
            return "success";
        } catch (error) {
            console.error("Error:", error);
            return "error";
        } finally {
            document.body.classList.remove('loading-indicator');
        }
    };
    return (
        <div style={{ width: "100%", marginTop: "5px", marginLeft: "5px" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
                <PrimaryButton
                    text={
                        <span style={{ color: "white", fontWeight: "bold" }}>Export</span>
                    }
                    onClick={(e) => {
                        exportData();
                    }}
                />
            </div>
        </div>
    );
};

export default Availablejobs;
