import React, { useState, useEffect,useRef } from "react";
import axios from "axios";
import { baseUrl } from "../Services/Constants";
import { processAndUpdateToken } from "../Services/CommonFunctions";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { SimpleSnackbarWithOutTimeOut } from '../Components/SnackBar';
import { Icon } from "office-ui-fabric-react/lib/Icon";



const CleanDataDialog = (props) => {
  const container = useRef();
  const [fileData, setFileData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [expanded, setExpanded] = useState({}); // Track which accordion is open

  useEffect(() => {
    if (props?.data?.JobId && props?.data?.JobId?.length > 0) {
      getData(props?.data?.JobId);
    }
  }, [props?.data?.JobId]);

  const getData = async (JobId) => {
    const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
    await getDocumentData(JobId, Token);
  };

  const getDocumentData = async (JobId, Token) => {
    const headers = {
      Authorization: `Bearer ${Token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(
      `${baseUrl}/api/ProcedureData/GetByJobIdCleandata?jobId=${JobId}`,
      { headers }
    );
    if (
      response?.data &&
      Array.isArray(response?.data) &&
      response?.data?.length > 0
    ) {
      var  ResData = response.data.filter(e => e.TemplateData.length > 0);
      setFileData(ResData); 
      initializeSelectedOptions(ResData);
    //   initializeExpandedState();   // we want to enable if we want to expand all the Accordion initially
    }
  };

  const initializeSelectedOptions = (data) => {
    const initialState = {};
    data.forEach((item) => {
      if (item.TemplateData) {
        initialState[item.Tablename] = []; 
      }
    });
    setSelectedOptions(initialState);
  };

//   const initializeExpandedState = (data) => {
//     const initialState = {};
//     data.forEach((item) => {
//       initialState[item.Tablename] = true; 
//     });
//     setExpanded(initialState);
//   };

  const handleAccordionToggle = (tableName) => {
    setExpanded((prevState) => ({
      ...prevState,
      [tableName]: !prevState[tableName], 
    }));
  };

  const handleCheckboxChange = (tableName, index, value) => {
    setSelectedOptions((prevState) => {
      const currentSelections = prevState[tableName] || [];
      if (currentSelections.includes(value)) {
        return {
          ...prevState,
          [tableName]: currentSelections.filter((item) => item !== value),
        };
      } else {
        return {
          ...prevState,
          [tableName]: [...currentSelections, value],
        };
      }
    });
  };

  const handleClean = async (tableName) => {
    document.body.classList.add( 'loading-indicator' );
    const selectedQuestions = selectedOptions[tableName] || []; 
    if (selectedQuestions.length === 0) {
      return;
    }
  

    try {
      const Token = await processAndUpdateToken(sessionStorage.getItem("token"));
      const headers = {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      };
  
        const apiUrl = `${baseUrl}/api/ProcedureData/delete-records`;

    const response = await axios( {
        method: "DELETE",
        url: apiUrl,
        headers: headers,
        data: {
            JobId: props?.data?.JobId || "", 
             ChecklistQuestions: selectedQuestions,
            TableName: tableName,
        }
    } );
  
      if (response.status === 200) {
        setTimeout(() => {
            container.current.showSnackbar(
                "Data Cleaned Successfully.", 
                "info", 
                true
            );
            setTimeout(() => {
                container.current.hideSnackbar(); 
            }, 2000); 
        }, 100);
        getData(props?.data?.JobId);
      } else {
        alert("Failed to delete records!");
      }
    } catch (error) {
        setTimeout(() => {
            container.current.showSnackbar(
                "Update Failed", 
                "error", 
                true
            );
            setTimeout(() => {
                container.current.hideSnackbar(); 
            }, 2000); 
        }, 100);
    }
    document.body.classList.remove( 'loading-indicator' );
  };
  

  return (
    <div>
        {/* dynamic process for each Accordion */}
      {fileData.map((item) => (
        <Accordion
          key={item.Tablename}
          expanded={expanded[item.Tablename] || false} 
        >
          <AccordionSummary onClick={() => handleAccordionToggle(item.Tablename)} 
           expandIcon={<Icon iconName="ChevronDown" />}
          >
            <Typography variant="h6">{item.Tablename.replace(/([A-Z])/g, " $1")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <List>
                {item.TemplateData.map((option, index) => (
                  <ListItem key={index} style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={(selectedOptions[item.Tablename] || []).includes(option)}
                          onChange={() => handleCheckboxChange(item.Tablename, index, option)}
                        />
                      }
                      label={option}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </AccordionDetails>
          <AccordionActions>
            <Button onClick={() => handleClean(item.Tablename)} color="primary">
              Clean
            </Button>
            <Button
              onClick={() => handleAccordionToggle(item.Tablename)} // Close the accordion
              color="secondary"
            >
              Close
            </Button>
          </AccordionActions>
        </Accordion>
      ))}
        <SimpleSnackbarWithOutTimeOut ref={ container } />
    </div>
  );
};

export default CleanDataDialog;
