import React, { useEffect } from 'react';

const TimeoutError = () => {

    return (
        <div className="unbody">
            <div className="uacontainer">
                <h1 className="unh1">Something Went Wrong</h1>
                <p className="unp">Request Timeout.Please refresh and try again.</p>
            </div>
        </div>
    );
}

export default TimeoutError;